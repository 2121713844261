<script>
  export let type;
</script>

<style>
  svg {
    fill: rgba(0, 0, 0, 0.7);
    margin-right: 4px;
    height: 12px;
    width: 12px;
  }
  svg:hover {
    fill: #242535;
  }
</style>

{#if type === 'project'}
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

    <path
      d="M437.018,74.982C388.666,26.628,324.379,0,255.998,0S123.332,26.629,74.981,74.982
      c-48.351,48.352-74.98,112.639-74.98,181.02s26.628,132.667,74.98,181.019C123.332,485.372,187.618,512,255.998,512
      s132.667-26.628,181.02-74.98c48.353-48.351,74.981-112.638,74.981-181.019S485.371,123.334,437.018,74.982z
      M450.067,178.679
      c-9.325,0.717-16.629-1.99-25.878-5.418c-6.35-2.353-13.548-5.021-21.987-6.698c-22.742-4.519-46.069,7.879-59.43,31.587
      c-3.891,6.904-22.498,42.451-6.665,64.26c5.936,8.176,13.878,12.937,20.261,16.761c8.564,5.133,17.418,10.44,27.45,14.488
      c2.136,0.862,4.251,1.643,6.295,2.397c6.084,2.246,12.376,4.569,14.626,7.65c1.261,1.726,2.577,5.614,1.68,14.325
      c-0.601,5.827-2.933,12.186-5.402,18.918c-6.082,16.586-14.413,39.304,5.108,61.608c3.494,3.991,7.437,7.371,11.717,10.122
      c-40.615,43.029-98.144,69.93-161.844,69.93c-122.746,0-222.606-99.862-222.606-222.607c0-23.651,3.721-46.447,10.585-67.848
      c3.564,5.1,7.588,9.634,11.448,13.697c12.347,12.999,25.996,24.88,40.629,35.356c1.085,0.777,2.63,1.883,3.435,2.607
      c0.55,2.459,0.329,6,0.08,10.033c-0.53,8.547-1.257,20.252,5.198,32.569c4.205,8.024,10.092,14.2,15.286,19.65
      c5.058,5.307,9.426,9.889,10.647,14.334c0.87,3.168,0.5,7.829,0.107,12.765c-0.54,6.798-1.153,14.505,0.808,22.796
      c2.636,11.143,8.927,19.4,14.477,26.685c3.499,4.592,6.804,8.931,8.238,12.903c1.105,3.065,1.552,7.197,2.023,11.571
      c0.473,4.385,1.01,9.355,2.32,14.473c3.926,15.336,16.655,30.052,33.831,30.051c0.915,0,1.846-0.042,2.786-0.128
      c17.786-1.625,29.999-17.517,31.871-41.477c0.303-3.878,1.101-14.101,3.079-16.356c0.612-0.244,2.103-0.609,3.209-0.879
      c4.705-1.152,11.814-2.894,17.688-8.91c10.819-11.08,7.836-24.971,6.053-33.27c-0.536-2.499-1.145-5.333-1.11-6.81
      c0.095-3.955,8.196-10.874,11.246-13.479l30.516-26.066c11.881-10.047,16.325-27.524,11.059-43.487
      c-3.76-11.396-12.082-21.643-24.069-29.635c-14.789-9.857-30.778-12.384-43.861-6.933c-4.449,1.853-8.046,4.395-10.937,6.438
      c-0.875,0.619-2.028,1.434-2.966,2.029c-0.431-0.48-0.928-1.144-1.389-1.99c-1.1-2.018-2.088-4.638-3.135-7.412
      c-1.378-3.65-2.94-7.788-5.251-11.953c-12.367-22.285-32.569-24.757-47.319-26.561c-2.521-0.308-4.903-0.6-7.278-0.967
      c-12.417-1.921-25.049-7.373-34.746-14.875c3.554-1.278,7.898-2.263,10.236-2.172c1.692,0.056,3.675,0.282,5.777,0.521
      c6.023,0.687,13.516,1.54,21.592-0.724c8.153-2.285,14.164-6.98,18.993-10.752c1.378-1.076,2.679-2.093,3.879-2.935
      c2.26-1.586,6.657-1.953,11.312-2.342c11.593-0.968,35.716-2.981,39.131-36.524c1.69-16.596-4.372-31.454-9.243-43.393
      c-4.051-9.932-7.982-19.79-11.412-29.991c12.285-2.106,24.906-3.219,37.785-3.219c27.634,0,54.101,5.077,78.538,14.32
      c-0.732,0.72-1.466,1.439-2.197,2.156c-7.421,7.278-15.095,14.805-20.376,21.642c-2.233,2.891-5.969,7.728-7.071,14.694
      c-1.867,11.818,5.2,20.897,8.218,24.773c4.484,5.763,11.992,15.409,24.393,18.502c2.77,0.69,5.444,0.986,8.01,0.986
      c9.344,0,17.292-3.913,23.454-6.948c3.548-1.747,7.217-3.554,9.41-3.73c2.088-0.171,5.774,1.044,9.345,2.214
      c5.214,1.71,11.123,3.646,18.084,3.899c9.157,0.328,17.278-2.989,23.651-9.252c14.783,18.364,26.715,39.111,35.119,61.571
      c-1.19,0.031-2.402,0.048-3.484,0.061C457.818,178.322,454.109,178.369,450.067,178.679z" />

  </svg>
{:else if type === 'github'}
  <svg version="1.1" viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38
      0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01
      1.08.58 1.23.82.72 1.21 1.87.87
      2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95
      0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18
      1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16
      1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65
      3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013
      8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
  </svg>
{:else if type === 'screenshots'}
  <svg viewBox="0 0 488.455 488.455" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m287.396 216.317c23.845 23.845 23.845 62.505 0 86.35s-62.505
      23.845-86.35 0-23.845-62.505 0-86.35 62.505-23.845 86.35 0" />
    <path
      d="m427.397 91.581h-42.187l-30.544-61.059h-220.906l-30.515
      61.089-42.127.075c-33.585.06-60.925 27.429-60.954 61.029l-.164 244.145c0
      33.675 27.384 61.074 61.059 61.074h366.338c33.675 0 61.059-27.384
      61.059-61.059v-244.236c-.001-33.674-27.385-61.058-61.059-61.058zm-183.177
      290.029c-67.335 0-122.118-54.783-122.118-122.118s54.783-122.118
      122.118-122.118 122.118 54.783 122.118 122.118-54.783 122.118-122.118
      122.118z" />
  </svg>
{/if}
